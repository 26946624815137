import React from "react"
import { graphql } from "gatsby"
import Loadable from "react-loadable"
import { Button } from "../components/Buttons"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/Containers"

const loader = () => <></>

const FareharborLoadable = Loadable({
  loader: () => import("../hooks/useFareharbor"),
  loading: loader
})

const CalendarPage = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const { heading, button } = data.allUniquePagesJson.nodes[0]

  return (
    <Layout className="calendar-page" whiteNav>
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
      />

      <div className="nav-desktop-spacer" />

      <Section>
        <Container small className="calendar-page__hero">
          <h1 className="has-text-centered">{heading}</h1>
          <Button
            className="mx-auto"
            buttonText={button.buttonText}
            href={button.href}
            destination={button.destination}
            appearance={button.appearance}
          />
        </Container>
      </Section>

      <Section id="fh-calendar" smallSpacing colorBack>
        <Container small>
          <div id="fh-calendar">
            <FareharborLoadable />
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export const calendarQuery = graphql`
  query calendarquery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        button {
          buttonText
          appearance
          destination
          href
        }
      }
    }
  }
`

export default CalendarPage
